import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import {JobWrapper, JobType} from './job.stc'
import Heading from '../shared/heading'
import Text from '../shared/text';
  
const Project = ({id, title, email, contact, skills, experience, isEven, ...restProps}) => {
    const {HeadingStyle, TextStyle, ...restStyles} = restProps
    
    return (
        <JobWrapper {...restStyles}>
            <div className={`col-11 offset-1`}>
                <div className="content row">
                    {title && (
                        <JobType className="wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                            <Heading {...HeadingStyle}>JOB TITLE</Heading>
                            <Text {...TextStyle}>{title}</Text>
                        </JobType>
                    )}
                    {contact && (
                        <JobType className="wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                            <Heading {...HeadingStyle}>CONTACT NO</Heading>
                            <Text {...TextStyle}>{contact}</Text>
                        </JobType>
                    )}
                    {email && (
                        <JobType className="wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                            <Heading {...HeadingStyle}>EMAIL TO</Heading>
                            <Text {...TextStyle}>{email}</Text>
                        </JobType>
                    )}
                    {experience && (
                        <JobType className="wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                            <Heading {...HeadingStyle}>EXPERIENCE</Heading>
                            <Text {...TextStyle}>{experience}</Text>
                        </JobType>
                    )}
                    {skills && (
                        <JobType className="wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                            <Heading {...HeadingStyle}>SKILLS</Heading>
                            <Text {...TextStyle}>{skills}</Text>
                        </JobType>
                    )}
                </div>
            </div>
        </JobWrapper>
    )
}

Project.propTypes = {
    HeadingStyle: PropTypes.object,
    TextStyle: PropTypes.object
}

Project.defaultProps = {
    HeadingStyle: {
        as: 'h6',
        color: '#001c43',
        fontSize: '12px',
        fontweight: 700,
        letterspacing: '2px',
        mb: '12px',
        responsive: {
            small: {
                letterspacing: '1px',
                mb: '5px'
            }
        }
    },
    TextStyle: {
        fontSize: '12px',
        letterspacing: '1px',
        mb: 0
    }
}

export default Project;