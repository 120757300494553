import React from "react";
import SEO from "../components/seo";
import Layout from "../containers/layout/layout";
import Banner from '../containers/company/banner';
import Job from '../containers/careers/job';

export default () => (
  <Layout>
    <SEO title="Careers" />
    <Banner/>
    <Job />
  </Layout>
);