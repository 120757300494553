import styled, {css} from 'styled-components';
import {device} from '../../theme'

export const JobWrapper = styled.div `
    margin-top: ${props => props.mt};
    ${props => props.responsive && css `
        ${props => props.responsive.medium && css `
            @media ${device.medium}{
                margin-top: ${props => props.responsive.medium.mt};
            }
        `};
    `}
`;

export const JobType = styled.div `
    flex-basis: 20%;
    @media ${device.small}{
        flex-basis: 100%;
        padding: 15px 0;
    }
    @media ${device.xsmall}{
        padding: 10px 0;
    }
`;
