import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import SectionTitle from '../../../components/shared/section-title'
import Job from '../../../components/job-item';
import {JobSectionWrap} from './job.stc';

const JobSection = ({section, project}) => {
    const jobQueryData = useStaticQuery(graphql `
    query JobPageDataQuery {
        homedataJson(id: {eq: "job_section_content"}) {
            id
            title
            subtitle
        }
        jobsJson (id: {eq: "job-list"}) {
            id
            jobs {
                id
                title
                email
                contact
                description
                experience
                skills
            }
        }
    }  
    `);
    const jobSecData = jobQueryData.homedataJson;
    const jobsData = jobQueryData.jobsJson.jobs;
    return (
        <JobSectionWrap {...section}>
            <div className="project-container">
                <div className="col-1 offset-1">
                    <SectionTitle
                        title={jobSecData.title}
                        subtitle={jobSecData.subtitle}
                    />
                </div>
 
                <div className="project-wrapper">
                    {jobsData.map((data, i) => {
                        let isEven = i%2 === 0;
                        return(
                            <Job 
                                {...project}
                                isEven={isEven}
                                key={data.id} 
                                id={data.id} 
                                title={data.title}
                                contact={data.contact}
                                email={data.email}
                                skills={data.skills}
                                experience={data.experience}
                            />
                        )
                    })}
                </div>
            </div>
        </JobSectionWrap>
    )
}

JobSection.propTypes = {
    section: PropTypes.object,
    project: PropTypes.object
}

JobSection.defaultProps = {
    section: {
        backgroundColor: '#f8f8f8'
    },
    project: {
        mt: '50px',
        responsive: {
            medium: {
                mt: '30px'
            }
        }
    }
}

export default JobSection;
